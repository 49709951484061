export default {
	state: {
		id: null
	},
	mutations: {
		setWarehouse(state, data){
			state.id = data
		},
	}
}
