import fileApi from "@/api/common/file";

//上传配置

export default {
	apiObj: fileApi.upload,			//上传请求API对象
	successCode: 200,					//请求完成代码
	maxSize: 5,						//最大文件大小 默认10MB
	parseData: function (res) {
		return {
			code: res.statusCode,				//分析状态字段结构
			src: res.data.url,			//分析图片远程地址结构
			msg: res.errors		//分析描述字段结构
		}
	}
}
