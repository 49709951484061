import config from "@/config"
import http from "@/utils/request"

export default {
	dict: {
		name: "获取系统字典选项",
		dataUrl: `${config.API_URL}/system/config/dict`,
		get: async function (code) {
			return await http.get(this.dataUrl + "?code=" + code);
		},

	},
	accounts: {
		name: "获取当前帐号的子帐号列表",
		dataUrl: `${config.API_URL}/system/config/accounts`,
		get: async function (data) {
			return await http.get(this.dataUrl,data);
		},
	},
	tenants: {
		name: "获取租户列表",
		dataUrl: `${config.API_URL}/system/config/tenants`,
		get: async function (data) {
			return await http.get(this.dataUrl,data);
		},
	},
	enum: {
		name: "获取系统枚举项",
		dataUrl: `${config.API_URL}/system/config/enum`,
		get: async function () {
			return await http.get(this.dataUrl);
		},
	},
	platform: {
		name: "获取平台列表",
		dataUrl: `${config.API_URL}/system/config/platform`,
		get: async function () {
			return await http.get(this.dataUrl);
		},
	},
}
