const DEFAULT_CONFIG = {
	//标题
	APP_NAME: "无忧ERP",

	//首页地址
	DASHBOARD_URL: "/work",

	//版本号
	APP_VER: "1.0.0",

	//内核版本号
	CORE_VER: "1.0.0",

	//接口地址
	API_URL: "/api",

	//请求超时
	TIMEOUT: 30000,

	//TokenName
	TOKEN_NAME: "Authorization",

	//Token前缀，注意最后有个空格，如不需要需设置空字符串
	TOKEN_PREFIX: "Bearer ",

	//追加其他头
	HEADERS: {},

	//请求是否开启缓存
	REQUEST_CACHE: false,

	//布局 默认：default | 通栏：header | 经典：menu | 功能坞：dock
	//dock将关闭标签和面包屑栏
	LAYOUT: 'header',

	//菜单是否折叠
	MENU_IS_COLLAPSE: false,

	//菜单是否启用手风琴效果
	MENU_UNIQUE_OPENED: false,

	MENU_DEFAULT_OPENED:[
		'/develop/data',
		'/product/library',
		'/purchase/config',
		'/purchase/need',
		'/stock/receive',
		'/stock/self',
		'/stock/in',
		'/stock/quality',
		'/collect/library'
	],

	//是否开启多标签
	LAYOUT_TAGS:false,

	//语言
	LANG: 'zh-cn',

	//主题颜色
	COLOR: '',

	//控制台首页默认布局
	DEFAULT_GRID: {
		//默认分栏数量和宽度 例如 [24] [18,6] [8,8,8] [6,12,6]
		layout: [24],
		//小组件分布，com取值:views/home/components 文件名
		copmsList: [
			['welcome'],
		]
	}
}

// 如果生产模式，就合并动态的APP_CONFIG
// public/config.js
if(process.env.NODE_ENV === 'production'){
	Object.assign(DEFAULT_CONFIG, APP_CONFIG)
}

module.exports = DEFAULT_CONFIG
