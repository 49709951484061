<template>
	<el-main>
		<p style="color: #999999">请输入网络图片链接，点击回车可添加图片。输入多个图片链接，请用逗号分隔</p>
		<el-input v-model="imageUrl" type="textarea" :rows="5" placeholder="请输入网络图片链接" @keyup.enter="addImage"
			style="margin:10px 0">
		</el-input>
	</el-main>
	<footer>
		<dialogBottom :Imgclass="Imgclass" :skuValue="skuValue" :masterPic="masterPic"></dialogBottom>
	</footer>
</template>

<script>
// import axios from 'axios';
import dialogBottom from "@/components/scAddPic/dialogBottom"
export default {
	name: "netPic",
	props: {
		skuValue: {
			type: [String, Object],
			default: ''
		},
		Imgclass: {
			type: String,
			default: ''
		},
		picIndex: {
			type: Number,
			default: 0,
		}
	},
	components: {
		dialogBottom
	},
	data() {
		return {
			imageUrl: '',
			masterPic: []
		}
	},
	methods: {
		addImage() {
			this.masterPic = this.imageUrl.trim().split(/,|，|\s+/).map(item => { return { url: item } })
			if (this.Imgclass == 'sku') {
				this.masterPic.forEach(item => {
					if (this.$store.state.select.selectPicArrsku.find(item => {
						return Object.entries(item.atName).every(([prop, value]) => {
							return this.skuValue[prop] === value;
						});
					}).selectimg.length <= 7) {
						this.$store.commit("SET_selectPicArrsku", { selectimg: item, atName: this.skuValue })
					} else {
						this.$message({
							message: '最多上传8张',
							type: 'info',
						});
					}
				})
			} else if (this.Imgclass == 'shop') {
				this.masterPic.forEach(item => {
					if (this.$store.state.select.selectPicArr.length <= 7) {
						this.$store.commit("SET_selectPicArr", item)
					} else {
						this.$message({
							message: '最多上传8张',
							type: 'info',
						});
					}
				})
			} else if (this.Imgclass == 'Ozonsku') {
				let { OzonselectPicArrsku } = this.$store.state.select
				this.masterPic.forEach(item => {
					if (OzonselectPicArrsku[this.picIndex].images.length <= 15) {
						this.$store.commit("Ozon_PushImg", { img: item, index: this.picIndex })
					} else {
						this.$message({
							message: '最多上传15张',
							type: 'info',
						});
					}
				})
			}
		}
	},
	mounted() {

	}
}
</script>

<style scoped lang="scss">
.text {
	height: 29px;
	width: 860px;
	background-color: #f4f6f9;
	line-height: 29px;
	text-indent: 1rem;
}

.pic {
	width: 860px;
	height: 85px;
	display: flex;

	.boxPic {
		position: relative;
		margin: 5px;
		width: 70px;
		height: 70px;
		overflow: hidden;

		li {
			width: 70px;
			height: 70px;
			background-color: orange;
			;

			img {
				width: 70px;
				height: 70px;
				overflow: hidden;
			}
		}

	}

	.line {
		width: 100%;
		height: 20px;
		border-top: 2px solid #eeeeee;
	}
}
</style>