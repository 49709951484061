<template>
	<nav style="display: flex">
		<el-upload ref="upload" :headers="token" class="upload-demo" :show-file-list="false" :action="`${uploadUrl}`"
			:before-upload="handleBeforeUpload" :on-success="handleUploadSuccess">
			<el-button type="primary">点击上传</el-button>
		</el-upload>
		<div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
		<el-alert style="width: 80%;margin-left: 8px;height: 30px"
			title="上传图片支持jpeg、jpg、png、bmp、gif格式，为保障前端加载顺利，单张图片大小不超过5M" type="warning" show-icon>
		</el-alert>
	</nav>

	<article class="center">
		<div class="right">
			<div class="header">
				<el-select v-model="valueSelect" placeholder="按上传时间从晚到早" style="margin-bottom: 10px;">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<el-button type="info" plain>搜索</el-button>
				<el-switch style="margin: 0 10px" v-model="valueSwitch" active-color="#4a81ff" inactive-color="#e6e6e6">
				</el-switch>
				<span style="color: #4a81ff">800px x 800px</span>
			</div>
			<div class="main" style="height: 233px" ref="nonePic" v-if="this.valueSwitch == true">
				<el-empty description="当前文件夹未找到符合要求的图片" :image-size="100"></el-empty>
			</div>
			<ul class="upPic" v-if="this.valueSwitch == false" id="data-list">

				<li v-for="item in makeImgclass" :key="item" @click="makeImgclassClick(item)">
					<img :src="item.url" class="images" ref="ite">
					<circle-check class="circleCheck" v-if="item.checked" />
				</li>
			</ul>
			<div class="bottom" style="height: 55px;position: relative" v-if="this.valueSwitch == false">
				<el-pagination style="position: absolute;right: 5px;bottom: 5px" @current-change="handleCurrentChange"
					:page-size="PageSize" layout="total, prev, pager, next," :total="total">
				</el-pagination>
			</div>
			<div class="bottom" style="height: 55px;position: relative" v-if="this.valueSwitch == true">
				<el-pagination style="position: absolute;right: 5px;bottom: 5px" layout="prev, pager, next" background
					:total="10">
				</el-pagination>
			</div>
		</div>
	</article>
	<footer>
		<dialogBottom @changechecked="changechecked" @updateShared="updateShared" :TsImgUrl="TsImgUrl"
			:skuValue="skuValue" :Imgclass="Imgclass" :batchEdit="TsbatchEdit" :masterPic="allPicSpaceArr"
			:picIndex="picIndex">
		</dialogBottom>
	</footer>
</template>

<script>

import { Close, View, CaretBottom, CircleCheck } from "@element-plus/icons"
import dialogBottom from "@/components/scAddPic/dialogBottom";
export default {
	name: "picSpace",
	components: { Close, View, CaretBottom, CircleCheck, dialogBottom },
	props: {
		skuValue: {
			type: [String, Object],
			default: ''
		},
		Imgclass: {
			type: String,
			default: '',
		},
		allPicSpaceArr: {
			type: Array,
			default: () => []
		},
		picSpaceList: {
			type: Array,
			default: () => []
		},
		picSpaceArr: {
			type: Array,
			default: () => []
		},
		picSpaceArrsku: {
			type: Array,
			default: () => []
		},
		// 批量编辑图片列表
		picSpace_batchEdit: {
			type: Array,
			default: () => []
		},
		batchEdit: {
			type: Array,
			default: () => []
		},
		PageSize: {
			type: [Number],
			default: 10
		},
		PageIndex: {
			type: [Number],
			default: 1
		},
		total: {
			type: [Number],
			default: 0
		},
		picIndex: {
			type: Number,
			default: 0,
		}
	},
	data() {
		return {
			uploadUrl: this.$Upload.cloud + "?filedir=timePicture",
			circleCheck: false,
			options: [
				{
					value: '选项1',
					label: '按上传时间从晚到早'
				}, {
					value: '选项2',
					label: '按上传时间从早到晚'
				}, {
					value: '选项3',
					label: '按图片从大到小'
				}, {
					value: '选项4',
					label: '按图片从小到大'
				}, {
					value: '选项5',
					label: '按图片名降序'
				}, {
					value: '选项6',
					label: '按图片名升序'
				}],
			valueSelect: '',
			valueSwitch: false,
			data: [],
			isBoxVisible: false,
			liList: [],
			isInputVisible: false,
			input: '',
			showPageSizes: [10],
			errorMessage: '',
			show: 0,
			indexPage: 0,
			TsImgUrl: [],
			TsbatchEdit: [],
			flag: true,
			token: { Authorization: 'Bearer ' + localStorage.getItem('TOKEN').replace(/^"|"$/g, '') }
		}
	},

	emits: ['handleCurrentChange'],

	computed: {
		makeImgclass() {
			if (this.Imgclass) {
				if (this.Imgclass === 'shop') {
					return this.picSpaceArr;
				} if (this.Imgclass === 'batchEdit') {
					return this.picSpace_batchEdit;
				} else {
					return this.picSpaceArrsku;
				}
			} else {
				return this.picSpaceList;
			}
		},

		checkedList() {
			let arr = []
			this.allPicSpaceArr.forEach((item) => {
				if (item.checked == true) {
					arr.push(item)
				}
			})
			return arr
		},
	},
	watch: {
		'picSpaceArrsku': {
			handler(newValue) {
				this.HavCheckImg()
			},
			// deep: true
		},
	},
	methods: {
		//改变选择
		changechecked(url) {
			//批量编辑处理
			if (this.Imgclass == 'batchEdit') {
				const targetItem = this.picSpace_batchEdit.find(item => {
					return item.url === url;
				});
				if (targetItem) {
					targetItem.checked = false;
				}
				return targetItem; // 返回更新后的目标项
			} else {
				const isBox = this.picSpaceArrsku.find(item => {
					return item.url === url
				})
				if (isBox) {
					isBox.checked = false
				}
			}
		},

		//判断图片列表 选择点击事件
		makeImgclassClick(item) {
			switch (this.Imgclass) {
				case 'shop':
					return this.updateSharedValue(item);
				case 'batchEdit':
					return this.updataBatchEdit(item);
				case 'sku':
					return this.updateSharedValueSku(item);
				case 'Ozonsku':
					return this.OzonupdateSharedValueSku(item);
				default:
					return this.updateShared(item);
			}
		},

		//上传图片前
		handleBeforeUpload(file) {
			// 验证文件类型
			if (!file.type.startsWith('image/')) {
				this.errorMessage = '请选择图片文件';
				return false;
			}
			// 验证文件大小
			if (file.size > this.maxSize) {
				this.errorMessage = '文件大小超过限制';
				return false;
			}
			// 清空错误信息
			this.errorMessage = '';
			return true;
		},

		//上传图片
		handleUploadSuccess(res, file) {
			this.$refs.upload.clearFiles();
			if (res.statusCode == 200) {
				this.$message.success("上传成功");
			} else {
				this.$message.error(res.errors);
			}
			// 假设上传成功，触发upload事件，将文件传递给父组件
			this.$emit('upload', file);
			this.$emit("getPicSpaceData")
			this.$emit('handleCurrentChange', this.indexPage)
		},

		//页码改变
		handleCurrentChange(PageIndex) {
			this.indexPage = PageIndex
			this.$emit('handleCurrentChange', PageIndex)
		},

		//选中已有图片
		HavCheckImg() {
			if (this.Imgclass === 'Ozonsku') {
				// 如果 Imgclass 是 'Ozonsku'
				const matchedSku = this.$store.state.select.OzonselectPicArrsku[this.picIndex]?.images
				const skuurls = matchedSku.map(item => item.url);
				this.picSpaceArrsku.forEach(item => {
					item.checked = skuurls.includes(item.url); // 更新选中的商品图片状态
				});

			} else {
				// 查找匹配的 selectPicArrsku 数组元素
				const matchedSku = this.$store.state.select.selectPicArrsku.find(item => {
					return Object.entries(this.skuValue).every(([key, value]) => item.atName[key] === value);
				});

				if (matchedSku) {
					// 如果找到了匹配的 selectPicArrsku 数组元素，则更新选中图片的状态
					const skuurls = matchedSku.selectimg.map(item => item.url); // 获取匹配的 skuurls 数组
					this.picSpaceArrsku.forEach(item => {
						item.checked = skuurls.includes(item.url); // 更新选中的商品图片状态
					});
				} else {
					// 如果没有找到匹配的 selectPicArrsku 数组元素，则取消选中所有商品图片
					this.picSpaceArrsku.forEach(item => item.checked = false);
				}

			}

		},

		//弹出消息
		showMessage(message) {
			this.$message({ message, type: 'info' });
		},

		//lazada sku图片点击时
		updateSharedValueSku(item) {
			item.checked = !item.checked

			if (item.checked) {
				if (this.$store.state.select.selectPicArrsku.find(item => {
					return Object.entries(item.atName).every(([prop, value]) => {
						return this.skuValue[prop] === value;
					});
				}).selectimg.length <= 7) {
					this.$store.commit("SET_selectPicArrsku", { selectimg: item, atName: this.skuValue })
				} else {
					item.checked = false
					this.$message({
						message: '最多上传8张',
						type: 'info',
					});
				}
			} else {
				this.$store.commit("REMOVE_selectPicArrsku", { selectimg: item, atName: this.skuValue })
			}
		},

		// ozon图片点击时
		OzonupdateSharedValueSku(item) {
			item.checked = !item.checked
			const imgList = this.$store.state.select.OzonselectPicArrsku[this.picIndex].images
			if (item.checked) {
				console.log(imgList);
				if (imgList.length <= 14) {
					this.$store.commit("Ozon_PushImg", { img: item, index: this.picIndex })
				} else {
					item.checked = false
					this.$message({
						message: '最多上传15张',
						type: 'info',
					});
				}
			} else {
				this.$store.commit("Ozon_RemoveImg", { img: item, index: this.picIndex })
			}
		},


		//商品图片点击时
		updateSharedValue(item) {
			item.checked = !item.checked
			if (item.checked) {
				if (this.$store.state.select.selectPicArr.length <= 7) {
					this.$store.commit("SET_selectPicArr", item)
				} else {
					item.checked = false
					this.$message({
						message: '最多上传8张',
						type: 'info',
					});
				}
			} else {
				this.$store.commit("REMOVE_selectPicArr", item)
			}
		},

		//富文本图片点击时
		updateShared(item) {
			item.checked = !item.checked
			if (item.checked) {
				this.TsImgUrl.push(item)
			} else {
				var index = this.TsImgUrl.findIndex(function (obj) {
					return obj.id === item.id
				});
				if (index !== -1) {
					// 使用 splice() 方法删除元素
					this.TsImgUrl.splice(index, 1);
				}
			}
		},

		//批量编辑图片点击时
		updataBatchEdit(item) {
			item.checked = !item.checked
			if (item.checked) {

				this.TsbatchEdit.push(item)
			} else {
				var index = this.TsbatchEdit.findIndex(function (obj) {
					return obj.id === item.id
				});
				if (index !== -1) {
					// 使用 splice() 方法删除元素
					this.TsbatchEdit.splice(index, 1);
					// this.batchEdit.splice(index, 1);
				}
			}
		},

		//批量编辑确定时
		BatchConfirm() {
			this.batchEdit.length = 0
			this.TsbatchEdit.forEach(item => {
				this.batchEdit.push(item)
			})
			this.TsbatchEdit = [];
		},

		//初始化
		getDefaultBatch(skuData) {
			let Data = JSON.parse(JSON.stringify(skuData))
			if (Data.images) {
				this.TsbatchEdit = Data.images
			} else {
				this.TsbatchEdit = []
			}
		},

	},

	mounted() {
		this.isBoxVisible = false;
		this.handleCurrentChange(0)

	},
}

</script>

<style scoped lang="scss">
.tip {
	display: inline-block;
	width: 600px;
	height: 30px;
	margin: -10px 0 0 5px;
}

.center {
	width: 100%;
	height: 320px;
	margin: 10px 0 5px 0;
	display: flex;

	.left {
		position: relative;
		flex: 1;
		background-color: #f5f5f5;

		.createDirectory {
			position: absolute;
			margin-left: 4px;
			margin-top: 2px;
			opacity: 0;
			transition: opacity 0.3s;
			color: #3370FF;
			font-size: 12px;
			float: left
		}

		.net,
		.all {
			height: 30px;
			width: 82%;
			margin-left: 9px;
			text-indent: 1rem;
			line-height: 30px;
			list-style: none;
		}

		.net {
			margin-top: 25px;
			overflow: hidden;
		}

		.all {
			margin-left: 10px;
			position: relative;

			p {
				position: relative;
			}

			.caretBottom {
				background-color: #fff;
				color: #b3d4fc;
				width: 12px;
				height: 12px;
				position: absolute;
				left: 45px;
				top: 9px;
				opacity: 0;
				transition: opacity 0.3s;
			}

			li {
				list-style: none;
				margin-left: 15px;
				position: relative;

				.caretBottom1 {
					background-color: #fff;
					color: #b3d4fc;
					width: 12px;
					height: 12px;
					position: absolute;
					left: 35px;
					top: 10px;
					opacity: 0;
					transition: opacity 0.3s;

					.diChild {
						span {}
					}
				}
			}

			.all:hover .caretBottom1 {
				opacity: 1;
			}
		}

		.net:hover {
			background-color: #e6e6e6;
		}

		.all:hover .caretBottom {
			opacity: 1;
		}
	}

	.right {
		flex: 4;

		.upPic {
			margin-left: 20px;
			height: 233px;
			list-style: none;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			li {
				position: relative;
				width: 100px;
				height: 100px;
				margin: 8px;

				img {
					width: 100%;
					height: 100%;
				}

				.circleCheck {
					position: absolute;
					right: 0;
					top: -3px;
					width: 20px;
					height: 20px;
					color: #999999
				}

				.overlay {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 30%;
					background-color: rgba(0, 0, 0, 0.3);
					opacity: 0;
					transition: opacity 0.3s;

					i {
						color: #fff;
						font-style: normal;
						padding: 2px;
					}
				}
			}

			li:hover .overlay {
				opacity: 1;
			}
		}
	}
}

.text {
	height: 29px;
	width: 860px;
	background-color: #f4f6f9;
	line-height: 29px;
	text-indent: 1rem;
}

.pic {
	width: 860px;
	height: 85px;
	display: flex;
	overflow-x: auto;
	white-space: nowrap;

	.boxPic {
		position: relative;
		margin: 5px;
		width: 70px;
		height: 70px;
		//overflow: hidden;

		img {
			width: 70px;
			height: 70px;
			overflow: hidden;
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 35%;
			background-color: rgba(0, 0, 0, 0.3);
			opacity: 0;
			transition: opacity 0.3s;

			::v-deep .el-icon-view {
				font-size: 12px;
				color: red;
			}
		}

		.overlayBottom {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 35%;
			background-color: rgba(0, 0, 0, 0.3);
		}
	}

	.boxPic:hover .overlay {
		opacity: 1;
	}

	.boxPic:hover .overlayBottom {
		opacity: 0;
	}
}

.line {
	width: 100%;
	height: 20px;
	border-top: 2px solid #eeeeee;
}
</style>