<template>
	<div class="sc-upload-multiple">
		<div class="sc-upload-list">
			<ul ref="itemBox" class="drag_ul">
				<li class="drag_li" v-for="(file, index) in fileList" :key="file.name">
					<div class="sc-upload-item">
						<div class="mask">
							<span class="del" @click.stop="del(index, file)"><el-icon><el-icon-delete /></el-icon></span>
						</div>
						<el-image class="image" :src="file.url" fit="cover"
							:initial-index="preview.findIndex(n => n == file.url)" :preview-src-list="preview"
							hide-on-click-modal preview-teleported>
							<template #placeholder>
								<div class="image-slot">
									<el-icon><el-icon-more-filled /></el-icon>
								</div>
							</template>
						</el-image>
					</div>
				</li>
			</ul>
			<div v-if="tip" class="el-upload__tip">*Tip: {{ tip }}</div>
		</div>
		<div class="sc-upload-uploader" @click="fileSelect && showfileSelect()">
			<el-upload ref="upload" class="uploader" :disabled="fileSelect" :action="action" :accept="accept" multiple
				:show-file-list="false" :file-list="defaultFileList" :before-upload="before" :on-progress="progress"
				:on-success="success" :on-change="change" :on-remove="remove" :on-error="error" :http-request="request">
				<div class="file-empty">
					<el-icon>
						<component :is="icon" />
					</el-icon>
					<h4 v-if="title">{{ title }}</h4>
				</div>
			</el-upload>
		</div>
		<el-dialog title="打开" v-model="fileSelectDialogVisible" :width="880" destroy-on-close>
			<sc-file-select multiple @submit="fileSelectSubmit">
				<template #do>
					<el-button @click="fileSelectDialogVisible = false">取 消</el-button>
				</template>
			</sc-file-select>
		</el-dialog>


		<el-dialog title="浏览图片" v-model="dialogVisible" width="900px" style="border-radius: 5px"
			:before-close="handleClose">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="图片空间" name="first">
					<pic-space :skuValue="skuValue" :picSpaceArrsku="picSpaceArrsku" :Imgclass="Imgclass" v-bind="$attrs"
						ref="picSpace"></pic-space>
				</el-tab-pane>
				<el-tab-pane label="网络图片" name="second">
					<net-pic :skuValue="skuValue" v-bind="$attrs"></net-pic>
				</el-tab-pane>
				<el-tab-pane label="引用采集图片" name="third">
					<gather-pic :skuValue="skuValue" v-bind="$attrs"></gather-pic>
				</el-tab-pane>
				<el-tab-pane label="引用商品主图" name="fourth">
					<shop-master :skuValue="skuValue" @value-updated="updateValue" v-bind="$attrs"></shop-master>
				</el-tab-pane>
			</el-tabs>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="confirm">确 定</el-button>
			</span>
		</el-dialog>
		<span style="display:none!important"><el-input v-model="value"></el-input></span>

	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import config from "@/config/upload";
const scFileSelect = defineAsyncComponent(() => import('@/components/scFileSelect'))
import Sortable from 'sortablejs';
import picSpace from "@/components/scAddPic/picSpace";
import shopMaster from "@/components/scAddPic/shopMaster";
import netPic from "@/components/scAddPic/netPic";
import gatherPic from "@/components/scAddPic/gatherPic";

export default {
	props: {
		modelValue: { type: String, default: "" },
		action: { type: String, default: "" },
		tip: { type: String, default: "" },
		dialogType: { type: String, default: "" },
		apiObj: { type: Object, default: () => { } },
		accept: { type: String, default: "image/gif, image/jpeg, image/png" },
		maxSize: { type: Number, default: config.maxSize },
		title: { type: String, default: "" },
		icon: { type: String, default: "el-icon-plus" },
		fileSelect: { type: Boolean, default: false },
		skuValue: { type: String, default: '' },
		Imgclass: { type: String, default: '', },
		over: { type: Array, default: () => [] },
		mode: { type: String, default: '' },
		picSpaceArrsku: { type: Array, default: () => [] },
	},
	components: {
		scFileSelect,
		picSpace,
		shopMaster,
		netPic,
		gatherPic,
	},
	data() {
		return {
			value: "",
			defaultFileList: [],
			fileList: [],
			fileSelectDialogVisible: false,
			dialogVisible: false,
			activeName: 'first',
			sharedValue: '',
			params: {
				PageSize: 8,
				PageIndex: 1,
				total: 0
			},
		}
	},
	watch: {
		modelValue() {
			this.$refs.upload.uploadFiles = this.modelValuetoArr
			this.fileList = this.modelValuetoArr
			this.value = this.modelValue
		},
		fileList: {
			handler() {
				if (this.isAllSuccess) {
					this.$emit('update:modelValue', this.fileListtoStr);
				}
			},
			deep: true
		}
	},
	computed: {
		modelValuetoArr() {
			return this.toArr(this.modelValue)
		},
		fileListtoStr() {
			return this.toStr(this.fileList)
		},
		preview() {
			return this.fileList.map(v => v.url)
		},
		isAllSuccess() {
			var all_length = this.fileList.length;
			var success_length = 0
			this.fileList.forEach(item => {
				if (item.status == "success") {
					success_length += 1
				}
			})
			return success_length == all_length
		}
	},
	mounted() {
		this.defaultFileList = this.toArr(this.modelValue);
		this.fileList = this.toArr(this.modelValue)
		this.value = this.modelValue
		this.rowDrop()
	},
	methods: {

		//拖拽
		rowDrop() {
			const _this = this
			const itemBox = this.$refs.itemBox;
			Sortable.create(itemBox, {
				handle: ".drag_li",
				animation: 200,
				ghostClass: "ghost",
				onEnd({ newIndex, oldIndex }) {
					const tableData = _this.fileList
					const currRow = tableData.splice(oldIndex, 1)[0];
					tableData.splice(newIndex, 0, currRow);
				}
			})
		},

		//关闭
		handleClose(done) {
			this.$confirm('确认关闭？')
				.then(_ => {
					done();
				})
				.catch(_ => { });
		},

		// 确定
		confirm() {
			this.getselectPicArr()
			this.dialogVisible = false

		},

		//获取选择图片(待定)
		getselectPicArr() {
			if (this.Imgclass === 'shop') {
				// 如果 Imgclass 为 'shop'，则为商品圖片，将 selectPic 设置为 selectPicArr 的值
				this.selectPic = Object.values(this.$store.state.select.selectPicArr);
				this.selectPicList = this.selectPic.map(item => { return item.url })
			} else if (this.skuValue !== '') {
				// 如果 sku值 不为空
				const findSku = item => {
					const atNameEntries = Object.entries(item.atName);
					// 使用 find 方法查找匹配的对象
					return atNameEntries.every(([key, value]) => this.skuValue[key] === value);
				};
				const selectedSku = this.$store.state.select.selectPicArrsku.find(findSku);
				console.log(selectedSku);
				if (selectedSku) {
					// 如果找到匹配的对象，将显示图片设置为匹配对象的选择图片
					if (typeof (selectedSku.selectimg) == 'string') {
						// 推送过来的数据由于是直接采集未经处理 需要处理一下
						if (selectedSku.selectimg.includes("url(")) {
							selectedSku.selectimg = selectedSku.selectimg.replace(/^url\(["']?([^"']*)["']?\)$/, '$1');
							if (selectedSku.selectimg.includes("_30x30.jpg")) {
								selectedSku.selectimg = selectedSku.selectimg.replace(/_30x30\.jpg$/, '');
								if (selectedSku.selectimg.includes('http') == false) {
									selectedSku.selectimg = `https:${selectedSku.selectimg}`;
								}
							}
						}
						selectedSku.selectimg = { url: selectedSku.selectimg }
						this.selectPicsku.push(selectedSku.selectimg)
					} else {
						this.selectPicsku = selectedSku.selectimg;
					}
					console.log(this.selectPicsku);
					this.selectPicskuList = this.selectPicsku.map(item => { return item.url })
				} else {
					this.selectPicskuList = []
				}
			}
		},


		//打开对话框
		showfileSelect() {
			if (this.dialogType == "custom") {
				if (this.Imgclass !== 'shop') {
					if (this.$refs.picSpace) {
						this.$refs.picSpace.handleCurrentChange(this.$refs.picSpace.indexPage);
					}
					const index = this.$store.state.select.selectPicArrsku.findIndex(item => {
						return Object.entries(this.skuValue).every(([prop, value]) => {
							return item.atName[prop] === value;
						});
					});
					if (index === -1) {
						this.$store.commit("SET_selectPicArrskuName", this.skuValue);
					}
				}
				this.dialogVisible = true
			} else {
				this.fileSelectDialogVisible = true
			}
		},

		//文件选择方法
		fileSelectSubmit(val) {
			const newval = [...this.modelValue.split(","), ...val].join(",")
			this.$emit('update:modelValue', newval);
			this.fileSelectDialogVisible = false
		},
		//默认值转换为数组
		toArr(str) {
			var _arr = [];
			console.log(str);
			var arr = str.split(",");
			arr.forEach(item => {
				if (item) {
					var urlArr = item.split('/');
					var fileName = urlArr[urlArr.length - 1]
					_arr.push({
						name: fileName,
						url: item
					})
				}
			})
			return _arr;
		},
		//数组转换为原始值
		toStr(arr) {
			var _arr = [];
			arr.forEach(item => {
				_arr.push(item.url)
			})
			var str = _arr.join(",")
			return str;
		},
		//上传方法
		before(file) {
			const maxSize = file.size / 1024 / 1024 < this.maxSize;
			if (!maxSize) {
				this.$message.warning(`上传文件大小不能超过 ${this.maxSize}MB!`);
				return false;
			}
		},
		//上传方法
		change(file, fileList) {
			file.tempImg = URL.createObjectURL(file.raw);
			this.fileList = fileList
		},
		//上传方法
		success(res, file) {
			var response = config.parseData(res);
			file.url = response.src
		},
		//上传方法
		progress() {

		},
		//上传方法
		remove() {

		},
		//上传方法
		error(err) {
			this.$notify.error({
				title: '上传文件错误',
				message: err
			})
		},

		// 删除
		del(index, item) {
			if (this.dialogType == "custom") {
				// console.log(item);
				// item = { url: item }
				this.$store.commit("REMOVE_selectPicArr", item)
				item.checked = !item.checked
				this.getselectPicArr()
			} else {
				this.fileList.splice(index, 1);
			}
		},

		//上传自定义方法
		request(param) {
			var apiObj = config.apiObj;
			if (this.apiObj) {
				apiObj = this.apiObj;
			}
			const data = new FormData();
			data.append("file", param.file);
			apiObj.post(data).then(res => {
				param.onSuccess(res)
			}).catch(err => {
				param.onError(err)
			})
		}
	}
}
</script>

<style scoped>
.el-form-item.is-error .sc-upload-uploader {
	border: 1px dashed #F56C6C;
}

.sc-upload-multiple {
	display: inline-block;
}


.sc-upload-list {
	display: inline-block;
}

.sc-upload-list li {
	list-style: none;
	display: inline-block;
	width: 120px;
	height: 120px;
	margin-right: 10px;
	vertical-align: top;
	box-sizing: border-box;
}

.sc-upload-item {
	position: relative;
	width: 100%;
	height: 100%;
}

.sc-upload-item .mask {
	display: none;
	position: absolute;
	top: 0px;
	right: 0px;
	line-height: 1;
	z-index: 1;
}

.sc-upload-item .mask span {
	display: inline-block;
	width: 25px;
	height: 25px;
	line-height: 23px;
	text-align: center;
	cursor: pointer;
	color: #fff;
}

.sc-upload-item .mask span i {
	font-size: 12px;
}

.sc-upload-item .mask .del {
	background: #F56C6C;
}

.sc-upload-item:hover .mask {
	display: inline-block;
}


.sc-upload-list .image {
	width: 100%;
	height: 100%;
}

.sc-upload-list .image-slot {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: #f5f7fa;
	color: #909399;
}

.sc-upload-list .image-slot i {
	font-size: 20px;
}

.sc-upload-uploader {
	border: 1px dashed #d9d9d9;
	width: 120px;
	height: 120px;
	display: inline-block;
	vertical-align: top;
	box-sizing: border-box;
}

.sc-upload-uploader:hover {
	border: 1px dashed #409eff;
}

.sc-upload-uploader .uploader {
	width: 100%;
	height: 100%;
}

.sc-upload-uploader:deep(.el-upload) {
	width: 100%;
	height: 100%;
}


.sc-upload-uploader .file-empty {
	width: 100%;
	height: 100%;
	line-height: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.sc-upload-uploader .file-empty i {
	font-size: 28px;
	color: #8c939d;
}

.sc-upload-uploader .file-empty h4 {
	font-size: 12px;
	font-weight: normal;
	color: #8c939d;
	margin-top: 10px;
}

.dialog-footer {
	position: absolute;
	bottom: 5px;
	right: 5px;
}
</style>
