<template>
	<div class="text">已选{{ makeImgclassLength
	}}张图片
	</div>
	<div class="pic">
		<div class="boxPic" v-for="(item, checked) in makeImgclass" :key="checked">
			<img :src="item.url">
			<span class="overlay">
				<span>
					<Close
						style="font-size: 2px;background-color: red;color:#fff;width: 15px;height: 15px;position: absolute;right: 0;top: 0px;cursor:pointer"
						@click="makeImgclassLengthClose(item, checked)" />
				</span>
			</span>
		</div>
	</div>
	<el-dialog :visible.sync="dialogVisibleimg">
		<img width="100%" :src="dialogImageUrl" alt="">
	</el-dialog>
	<div class="line"></div>
</template>

<script>
import { Close, View } from "@element-plus/icons"

export default {
	name: "dialogBottom",
	props: {
		skuValue: {
			type: [String, Object],
			default: ''
		},
		Imgclass: {
			type: String,
			default: '',
		},
		value: {
			type: Array,
			default: () => []
		},
		masterPic: {
			type: Array,
			default: () => []
		},
		TsSpuImgList: {
			type: Array,
			default: () => []
		},
		TsImgUrl: {
			type: Array,
			default: () => []
		},
		batchEdit: {
			type: Array,
			default: () => []
		},
		checkImg: {
			type: Array,
			default: () => []
		},
		picIndex: {
			type: Number,
			default: 0,
		}


	},
	emits: ['changechecked', 'updateShared', 'getDialogImg'],
	components: { Close, View },
	computed: {

		makeImgclass() {
			switch (this.Imgclass) {
				case 'shop':
					return this.selectPicArr;
				case 'sku':
					return this.selectPicArrsku;
				case 'goodImg':
					return this.checkImg;
				case 'netPic':
					return this.masterPic;
				case 'batchEdit':
					return this.batchEdit;
				case 'Ozonsku':
					return this.OzonselectPicArr;

				default:
					return this.selectSpaceList;
			}
		},

		makeImgclassLength() {
			switch (this.Imgclass) {
				case 'shop':
					return this.selectPicArr.length;
				case 'sku':
					return this.selectPicArrsku.length;
				case 'goodImg':
					return this.checkImg.length;
				case 'netPic':
					return this.masterPic.length;
				case 'batchEdit':
					return this.batchEdit?.length || 0;
				case 'Ozonsku':
					return this.OzonselectPicArr.length;
				default:
					return this.selectSpaceList.length;
			}
		},
		//spu图片
		selectPicArr() {
			return this.$store.state.select.selectPicArr
		},

		//Ozonsku图片
		OzonselectPicArr() {

			// console.log(this.$store.state.select.OzonselectPicArrsku[this.picIndex]);
			return this.$store.state.select.OzonselectPicArrsku[this.picIndex].images.map(item => { return item })
			// return []
		},

		//sku图片
		selectPicArrsku(data) {
			if (this.Imgclass === 'Ozonsku') {
				return this.$store.state.select.OzonselectPicArrsku[this.picIndex].images || [];
			} else {
				if (this.$store.state.select.selectPicArrsku.length == []) {
					return [];
				} else {
					return this.$store.state.select.selectPicArrsku.find(item => {
						const atNameEntries = Object.entries(item.atName);
						return atNameEntries.every(([key, value]) => data.skuValue[key] === value);
					})?.selectimg || [];
				}
			}
		},
		//富文本
		selectSpaceList() {
			return this.TsImgUrl
		}
	},
	data() {
		return {
			dialogImageUrl: '',
			dialogVisibleimg: false,
		}
	},
	mounted() {
		this.$emit('getDialogImg', this.selectPicArrsku)
	},
	methods: {

		//删除图片模式
		makeImgclassLengthClose(item, checked) {

			switch (this.Imgclass) {
				case 'shop':
					return this.handleRemove(item, checked)
				case 'sku':
					return this.handleRemovesku(item, checked)
				case 'goodImg':
					return this.handleRemoveShopSpace(item);
				case 'netPic':
					return this.netPicArr(item);
				case 'batchEdit':
					return this.handleRemovebatchEdit(item);
				case 'Ozonsku':
					return this.handleRemoveOzonsku(item);
				default:
					return this.handleRemoveSpace(item, checked)
			}
		},



		//商品图片在列表中移除
		handleRemove(item) {
			this.$store.commit("REMOVE_selectPicArr", item)
			item.checked = !item.checked
		},

		//Ozonsku图片在列表中移除
		handleRemoveOzonsku(item) {
			this.$store.commit("Ozon_RemoveImg", { img: item, index: this.picIndex })
			this.$emit('changechecked', item.url)
		},

		//sku图片在列表中移除
		handleRemovesku(item) {
			this.$store.commit("REMOVE_selectPicArrsku", { selectimg: item, atName: this.skuValue })
			this.$emit('changechecked', item.url)
		},

		//富文本图片空间列表移除
		handleRemoveSpace(item) {
			this.$emit('updateShared', item)
		},

		//富文本引用商品列表移除
		handleRemoveShopSpace(item) {
			item.checked = !item.checked
			if (item.checked) {
				this.checkImg.push(item)
			} else {
				var index = this.checkImg.findIndex(function (obj) {
					return obj.url === item.url
				});
				if (index !== -1) {
					// 使用 splice() 方法删除元素
					this.checkImg.splice(index, 1);
				}
			}
		},

		//富文本网络图片在列表中移除
		netPicArr(item) {
			var index = this.masterPic.findIndex(function (obj) {
				return obj.url === item.url
			});
			if (index !== -1) {
				// 使用 splice() 方法删除元素
				this.masterPic.splice(index, 1);
			}
		},

		//批量编辑图片在列表中移除
		handleRemovebatchEdit(item) {
			var index = this.batchEdit.findIndex(function (obj) {
				return obj.url === item.url
			});
			if (index !== -1) {
				// 使用 splice() 方法删除元素
				this.batchEdit.splice(index, 1);
			}
			this.$emit('changechecked', item.url)
		}
	}
}

</script>

<style scoped lang="scss">
.text {
	height: 29px;
	width: 860px;
	background-color: #f4f6f9;
	line-height: 29px;
	text-indent: 1rem;
}

.pic {
	width: 860px;
	height: 85px;
	display: flex;

	.boxPic {
		position: relative;
		margin: 5px;
		width: 70px;
		height: 70px;
		overflow: hidden;

		img {
			width: 70px;
			height: 70px;
			overflow: hidden;
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			// height: 35%;
			background-color: rgba(0, 0, 0, 0.3);
			opacity: 0;
			transition: opacity 0.3s;

			::v-deep .el-icon-view {
				font-size: 12px;
				color: red;
			}
		}

		.overlayBottom {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 35%;
			background-color: rgba(0, 0, 0, 0.3);
		}
	}

	.boxPic:hover .overlay {
		opacity: 1;
	}

	.boxPic:hover .overlayBottom {
		opacity: 0;
	}
}

.line {
	width: 100%;
	height: 35px;
	border-top: 2px solid #eeeeee;
}
</style>