export default {
	state: {
		enum:{}
	},
	mutations: {
		setEnum(state, data){
			state.enum = data
		},
	}
}
