// 静态路由配置
// 书写格式与动态路由格式一致，全部经由框架统一转换
// 比较动态路由在meta中多加入了role角色权限，为数组类型。一个菜单是否有权限显示，取决于它以及后代菜单是否有权限。
// routes 显示在左侧菜单中的路由(显示顺序在动态路由之前)
// 示例如下

// const routes = [
// 	{
// 		name: "demo",
// 		path: "/demo",
// 		meta: {
// 			icon: "el-icon-eleme-filled",
// 			title: "演示",
// 			role: ["SA"]
// 		},
// 		children: [{
// 			name: "demopage",
// 			path: "/demopage",
// 			component: "test/autocode/index",
// 			meta: {
// 				icon: "el-icon-menu",
// 				title: "演示页面",
// 				role: ["SA"]
// 			}
// 		}]
// 	}
// ]

const routes = [

	// {
	// 	"name":"develop",
	// 	"path": "/develop",
	// 	"component": "ss",
	// 	"redirect": null,
	// 	"meta": {
	// 		"type": "menu",
	// 		"title": "开发",
	// 		"icon": "el-icon-tools",
	// 		"hidden": 0
	// 	},
	// 	"children": [
	// 		{
	// 			"name":"develop_data",
	// 			"path": "/develop/data",
	// 			"component": "",
	// 			"redirect": null,
	// 			"meta": {
	// 				"type": "menu",
	// 				"title": "数据",
	// 				"icon": "el-icon-tools",
	// 				"hidden": 0
	// 			},
	// 			"children": [
	// 				{

	// 					"name":"develop_data_menu",
	// 					"path": "/develop/data/menu",
	// 					"component": "develop/menu",
	// 					"redirect": null,
	// 					"meta": {
	// 						"type": "menu",
	// 						"title": "菜单管理",
	// 						"icon": null,
	// 						"hidden": 0
	// 					},

	// 				},
	// 				{

	// 					"name":"develop_data_dict",
	// 					"path": "/develop/data/dict",
	// 					"component": "develop/dict",
	// 					"redirect": null,
	// 					"meta": {
	// 						"type": "menu",
	// 						"title": "字典管理",
	// 						"icon": "",
	// 						"hidden": 0
	// 					},
	// 					"children": []
	// 				},

	// 			]
	// 		}
	// 	]
	// },

	// {
	// 	"name": "home",
	// 	"path": "/home",
	// 	"meta": {
	// 		"title": "我的",
	// 		"icon": "el-icon-eleme-filled",
	// 		"type": "menu"
	// 	},
	// 	"children": [
	// 		{
	// 			"name": "dashboard",
	// 			"path": "/dashboard",
	// 			"meta": {
	// 				"title": "控制台",

	// 				"affix": true
	// 			},
	// 			"component": "home"
	// 		},
	// 		{
	// 			"name": "userCenter",
	// 			"path": "/ucenter",
	// 			"meta": {
	// 				"title": "个人信息",

	// 			},
	// 			"component": "userCenter"
	// 		},

	// 		{
	// 			"name": "my_shop",
	// 			"path": "/myShop",
	// 			"meta": {
	// 				"title": "我的店铺",

	// 			},
	// 			"component": "system/shop"
	// 		}
	// 	]
	// },

]

export default routes;
