export default {
	mounted: function (el, binding) {
		let [fn, param, event = "click", time = 1000] = binding.value
		let timer;
		el.addEventListener(event, () => {
			if(timer) return false
			fn(param);
			timer = setTimeout(() => timer = null, time)
		})
	},
}
