<template>
	<div class="ListBox">
		<!-- <el-checkbox v-model="checkAll" @change="handleCheckAllChange" style="width: 100%;">全选</el-checkbox> -->
		<ul class="gatherPic" v-if="masterPic.length > 0">
			<li v-for="(item, index) in masterPic" :key="index">
				<img :src="item.url" style="position: relative" @click="onImg(item)">
				<circle-check class="circleCheck" v-if="item.checked" />
			</li>
		</ul>
	</div>
	<footer>
		<dialogBottom @changechecked="changechecked" ref="dialog" :Imgclass="Imgclass" :checkImg="checkImg"
			:skuValue="skuValue" :masterPic="allPicSpaceArr">
		</dialogBottom>
	</footer>
</template>

<script>

import { CircleCheck } from "@element-plus/icons"
import dialogBottom from "@/components/scAddPic/dialogBottom";
export default {
	name: "shopMaster",
	emits: ['valueUpdated'],
	props: {
		skuValue: {
			type: [String, Object],
			default: ''
		},
		allPicSpaceArr: {
			type: Array,
			default: () => []
		},
		Imgclass: {
			type: String,
			default: ''
		},
		dialogVisible: {
			type: Boolean,
			default: false
		},
		picIndex: {
			type: Number,
			default: 0,
		}

	},
	computed: {
	},
	components: {
		CircleCheck,
		dialogBottom
	},
	data() {
		return {
			checkAll: false,
			checkVisible: false,
			masterPic: [],
			checkImg: []
		};
	},
	mounted() {
		this.getshopImg()
	},
	watch: {
		dialogVisible(newVal) {
			if (newVal) {
				this.getshopImg();
			}
		}
	},
	methods: {

		onImg(item) {
			if (this.Imgclass == 'sku') {
				this.updateSharedValueSku(item)
			} else if (this.Imgclass == 'Ozonsku') {
				this.OzonupdateSharedValueSku(item)
			} else {
				this.updateSharedValue(item)
			}
		},

		//改变选择
		changechecked(url) {
			this.masterPic.find(item => {
				return item.url == url
			}).checked = false
		},

		//获取列表图片
		getshopImg() {
			const spuImg = this.$store.state.select.selectPicArr.map(item => item.url);
			const OzonspuImg = this.$store.state.select.OzonselectPicArr;
			const skuImg = this.$store.state.select.selectPicArrsku.flatMap(item => item.selectimg.map(img => img.url));
			if (this.Imgclass == 'sku') {
				const mergedArray = Array.from(new Set(spuImg)).map(item => ({ url: item, checked: false }));
				for (const item1 of mergedArray) {
					for (const item2 of this.$refs.dialog.selectPicArrsku) {
						if (item1.url === item2.url) {
							item1.checked = true;
							break;
						}
					}
				}
				this.masterPic = mergedArray;
			} else if (this.Imgclass == 'Ozonsku') {
				const mergedArray = Array.from(new Set(OzonspuImg)).map(item => ({ url: item, checked: false }));
				for (const item1 of mergedArray) {
					for (const item2 of this.$refs.dialog.selectPicArrsku) {
						if (item1.url === item2.url) {
							item1.checked = true;
							break;
						}
					}
				}
				this.masterPic = mergedArray;
			} else {
				const mergedArray = Array.from(new Set([...spuImg, ...skuImg])).map(item => ({ url: item, checked: false }));
				this.masterPic = mergedArray;
			}
		},

		//sku图片点击
		updateSharedValueSku(item) {
			item.checked = !item.checked

			if (item.checked) {
				if (this.$store.state.select.selectPicArrsku.find(item => {
					return Object.entries(item.atName).every(([prop, value]) => {
						return this.skuValue[prop] === value;
					});
				}).selectimg.length <= 7) {
					this.$store.commit("SET_selectPicArrsku", { selectimg: item, atName: this.skuValue })
				} else {
					item.checked = false
					this.$message({
						message: '最多上传8张',
						type: 'info',
					});
				}
			} else {
				this.$store.commit("REMOVE_selectPicArrsku", { selectimg: item, atName: this.skuValue })
			}
		},
		// Ozonsku图片点击
		OzonupdateSharedValueSku(item) {
			item.checked = !item.checked
			const imgList = this.$store.state.select.OzonselectPicArrsku[this.picIndex].images
			if (item.checked) {
				if (imgList.length <= 15) {
					this.$store.commit("Ozon_PushImg", { img: item, index: this.picIndex })
					console.log(imgList);
				} else {
					item.checked = false
					this.$message({
						message: '最多上传15张',
						type: 'info',
					});
				}
			} else {
				this.$store.commit("Ozon_RemoveImg", { img: item, index: this.picIndex })
			}
		},

		//富文本点击图片
		updateSharedValue(item) {
			item.checked = !item.checked
			if (item.checked) {
				this.checkImg.push(item)
			} else {
				var index = this.checkImg.findIndex(function (obj) {
					return obj.url === item.url
				});
				if (index !== -1) {
					// 使用 splice() 方法删除元素
					this.checkImg.splice(index, 1);
				}
			}
		},
		//点击全选
		// handleCheckAllChange(val) {
		// 	this.masterPic.forEach((item) => {
		// 		item.checked = val
		// 	})
		// 	this.$emit('value-updated', this.masterPic)
		// }
	},
}
</script>

<style scoped lang="scss">
.ListBox {
	width: 100%;
	/* height: 280px; */
	margin: 5px 0 15px 0;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}

.gatherPic {
	width: 800px;
	/* height: 110px; */
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	//background-color: pink;
	li {
		//flex: 1;
		position: relative;
		width: 100px;
		height: 100px;
		margin: 5px;

		//background-color: pink;
		img {
			width: 100%;
			height: 100%;
		}

		.circleCheck {
			position: absolute;
			right: 0;
			top: -3px;
			width: 20px;
			height: 20px;
			color: #999999
		}
	}
}
</style>