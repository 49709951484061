import config from "@/config"

//系统路由
const routes = [
	{
		name: "layout",
		path: "/",
		component: () => import(/* webpackChunkName: "layout" */ '@/layout'),
		redirect: config.DASHBOARD_URL || '/dashboard',
		children: []
	},
	{
		path: "/login",
		component: () => import(/* webpackChunkName: "login" */ '@/views/ucenter/login'),
		meta: {
			title: "登录"
		}
	},
	{
		path: "/order",
		component: () => import(/* webpackChunkName: "login" */ '@/views/analysis/order'),
		meta: {
			title: "订单"
		}
	},
	{
		path: "/register",
		component: () => import('@/views/ucenter/register'),
		meta: { title: "注册" }
	},
	{
		path: "/forget",
		component: () => import('@/views/ucenter/forget'),
		meta: { title: "忘记密码" }
	}
]

export default routes;
