import { ElMessage } from 'element-plus';
export default {
	state: {
		selectPicArr: [],
		selectPicArrsku: [],
		OzonselectPicArrsku: [],
		OzonselectPicArr: [],
		state_attribute: [],
		checkedImage: [],
		checkedValue: [], //选中的值
	},
	mutations: {
		// 设置ozon图片
		SET_OzonselectPicArr(state, key) {
			state.OzonselectPicArrsku[key] = {
				index: key,
				images: state.OzonselectPicArrsku[key]
					? state.OzonselectPicArrsku[key].images
					: [],
			};
		},

		// ozon添加图片方法
		Ozon_PushImg(state, key) {
			state.OzonselectPicArrsku[key.index].images.push(key.img);
		},

		// ozon删除图片方法
		Ozon_RemoveImg(state, key) {
			let index = state.OzonselectPicArrsku[key.index].images.findIndex(
				(item) => {
					return item.url === key.img.url;
				}
			);
			state.OzonselectPicArrsku[key.index].images.splice(index, 1);
			// const arr = JSON.parse(JSON.stringify(state.OzonselectPicArrsku))
			// arr[key.index].images.splice(index, 1)
			// state.OzonselectPicArrsku = JSON.parse(JSON.stringify(arr))
		},

		SET_selectPicArrskuName(state, key) {
			let index = state.selectPicArrsku.findIndex(
				(item) => item.atName === key
			);
			if (index === -1) {
				state.selectPicArrsku.push({
					atName: key,
					selectimg: [],
				});
			}
		},

		SET_selectPicArrsku(state, key) {
			const { atName, selectimg } = key;
			const { selectPicArrsku } = state;

			// 获取点击的图片的sku类型的下标
			const index = selectPicArrsku.findIndex((item) => {
				return Object.entries(atName).every(([prop, value]) => {
					return item.atName[prop] === value;
				});
			});

			if (index === -1) {
				return;
			}

			const selectedSku = selectPicArrsku[index];
			if (selectedSku.selectimg.length <= 7) {
				// 获取在该sku中点击图片的下标
				const index1 = selectedSku.selectimg.findIndex(
					(item) => item.url === selectimg.url
				);
				// 不存在就添加进去
				if (index1 === -1) {
					selectedSku.selectimg.push({
						url: selectimg.url,
					});
				}
			} else {
				ElMessage({
					message: '最多上传8张',
					// type: 'error',
				});
			}
		},
		SET_selectPicArr(state, key) {
			let index = state.selectPicArr.findIndex((item) => item.url === key.url);
			if (state.selectPicArr.length <= 7) {
				if (index === -1) {
					state.selectPicArr.push(key);
				} else {
					console.log('已经存在');
				}
			} else {
				ElMessage({
					message: '最多上传8张',
				});
			}
		},
		REMOVE_selectPicArrsku(state, key) {
			const index = state.selectPicArrsku.findIndex((item) => {
				return Object.entries(key.atName).every(([prop, value]) => {
					return item.atName[prop] === value;
				});
			});
			let index1 = state.selectPicArrsku[index].selectimg.findIndex(
				(item) => item.url === key.selectimg.url
			);
			state.selectPicArrsku[index].selectimg.splice(index1, 1);
			if (key.type == 1) {
				state.selectPicArrsku.splice(index, 1);
			}
		},
		REMOVE_selectPicArr(state, key) {
			let index = state.selectPicArr.findIndex((item) => item.url === key.url);
			state.selectPicArr.splice(index, 1);
		},
		SET_state_attribute(state, arr) {
			state.state_attribute = arr;
		},
		SET_checkedImage(state, key) {
			state.checkedImage = key;
		},
		SET_checkedValue(state, key) {
			state.checkedValue = key;
		},
	},
	actions: {},
};
