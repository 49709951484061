<template>
	<ul class="gatherPic" v-if="gatherPic.length > 0">
		<li v-for="(item, index) in gatherPic" :key="index">
			<img :src="item.url" style="position: relative" @click="updateSharedValue(item)">
			<circle-check class="circleCheck" v-if="item.checked" />
		</li>
	</ul>
	<footer>
		<dialogBottom :skuValue="skuValue" :masterPic="allPicSpaceArr"></dialogBottom>
	</footer>
</template>

<script>
import { CircleCheck } from "@element-plus/icons"
import dialogBottom from "@/components/scAddPic/dialogBottom";
export default {
	name: "gatherPic",
	props: {
		skuValue: {
			type: [String, Object],
			default: ''
		},
		gatherPic: {
			type: Array,
			default: () => []
		},
		allPicSpaceArr: {
			type: Array,
			default: () => []
		},
	},
	components: {
		CircleCheck,
		dialogBottom
	},
	data() {
		return {
		}
	},
	computed: {
		checkedList() {
			let arr = []
			this.allPicSpaceArr.forEach((item) => {
				if (item.checked == true) {
					arr.push(item)
				}
			})
			return arr
		},
	},
	methods: {
		updateSharedValue(item) {
			item.checked = !item.checked
			if (item.checked) {
				this.$store.commit("SET_selectPicArr", item)
			} else {
				this.$store.commit("REMOVE_selectPicArr", item)
			}
		},
	},
}

</script>

<style scoped lang="scss">
.gatherPic {
	width: 800px;
	height: 110px;
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	li {
		position: relative;
		width: 100px;
		height: 100px;
		margin: 5px;

		img {
			width: 100%;
			height: 100%;
		}

		.circleCheck {
			position: absolute;
			right: 0;
			top: -3px;
			width: 20px;
			height: 20px;
			color: #999999
		}
	}
}

.text {
	height: 29px;
	width: 860px;
	background-color: #f4f6f9;
	line-height: 29px;
	text-indent: 1rem;
}

.pic {
	width: 860px;
	height: 85px;
	display: flex;

	.boxPic {
		position: relative;
		margin: 5px;
		width: 70px;
		height: 70px;
		overflow: hidden;

		li {
			width: 70px;
			height: 70px;

			img {
				width: 70px;
				height: 70px;
				overflow: hidden;
			}
		}

	}

	.line {
		width: 100%;
		height: 20px;
		border-top: 2px solid #eeeeee;
	}
}
</style>