export default {
	mounted: function (el, binding) {
		let [fn, event = "click", time = 300] = binding.value
		let timer
		let flag = true;//输入法标记
		if (event === "input") {
			el.addEventListener("compositionstart", () => {
				flag = false;
			})
			el.addEventListener("compositionend", () => {
				flag = true;
			})
		}
		el.addEventListener(event, () => {
			timer && clearTimeout(timer)
			timer = setTimeout(() => {
				if(flag)fn();
			}, time)
		})
	}

}
