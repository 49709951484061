import config from "@/config"
import http from "@/utils/request"

export default {
	token: {
		url: `${config.API_URL}/public/login`,
		name: "登录获取TOKEN",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	register: {
		url: `${config.API_URL}/public/register`,
		name: "自主注册",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	forget: {
		url: `${config.API_URL}/public/forget`,
		name: "重置密码",
		post: async function(data={}){
			return await http.post(this.url, data);
		}
	},

	menu: {
		url: `${config.API_URL}/ucenter/my-menu`,
		name: "获取当前用户的菜单",
		get: async function(){
			return await http.get(this.url);
		}
	}
}
